///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Form */

	form {
		margin: 0 0 _size(element-margin) 0;

		.field {
			margin: 0 0 (_size(element-margin) * 0.5) 0;

			&.half {
				width: 50%;
				float: left;
				padding: 0 0 0 0.75em;

				&.first {
					padding: 0 0.75em 0 0;
				}
			}
		}

		> .actions {
			margin: _size(element-margin) 0 0 0 !important;
		}

		@include breakpoint(small) {
			.field {
				&.half {
					width: 100%;
					float: none;
					padding: 0;

					&.first {
						padding: 0;
					}
				}
			}
		}
	}

	label {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
		line-height: 1.5;
		margin: 0 0 (_size(element-margin) * 0.35) 0;
		display: block;
		font-size: 1.1em;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		background: _palette(border-bg);
		border-radius: _size(border-radius);
		border: none;
		border: solid _size(border-width) _palette(border);
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}

		&:focus {
			border-color: _palette(fg-bold);
			box-shadow: 0 0 0 _size(border-width) _palette(fg-bold);
		}
	}

	.select-wrapper {
		@include icon;
		display: block;
		position: relative;

		&:before {
			color: _palette(border);
			content: '\f078';
			display: block;
			height: _size(element-height);
			line-height: _size(element-height);
			pointer-events: none;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: _size(element-height);
		}

		select::-ms-expand {
			display: none;
		}
	}

	select {
		option {
			background-color: _palette(bg);
			color: _palette(fg-bold);
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: _size(element-height);
	}

	textarea {
		padding: 0.75em 1em;

		body.is-ie & {
			min-height: 10em;
		}
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;

		& + label {
			@include icon;
			color: _palette(fg);
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: _font(weight);
			padding-left: (_size(element-height) * 0.6) + 0.75em;
			padding-right: 0.75em;
			position: relative;

			&:before {
				background: _palette(border-bg);
				border-radius: _size(border-radius);
				border: solid _size(border-width) _palette(border);
				content: '';
				display: inline-block;
				height: (_size(element-height) * 0.6);
				left: 0;
				line-height: (_size(element-height) * 0.575);
				position: absolute;
				text-align: center;
				top: 0;
				width: (_size(element-height) * 0.6);
			}
		}

		&:checked + label {
			&:before {
				background: _palette(fg-bold);
				border-color: _palette(fg-bold);
				color: _palette(accent3);
				content: '\f00c';
			}
		}

		&:focus + label {
			&:before {
				border-color: _palette(fg-bold);
				box-shadow: 0 0 0 _size(border-width) _palette(fg-bold);
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: _size(border-radius);
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	::-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	.formerize-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}