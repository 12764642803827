// Misc.
	$misc: (
		z-index-base:		10000,
		max-features:		20,
		max-sidebar-links:	20
	);

// Duration.
	$duration: (
		transition:			0.2s,
		activation:			1s
	);

// Size.
	$size: (
		border-radius:		0.25em,
		border-width:		1px,
		element-height:		2.75em,
		element-margin:		2em,
		sidebar-width:		18em,
		sidebar-height:		3.5em,	// when <=large is active
		inner-width:		75em
	);

// Font.
	$font: (
		family:				(Arial, Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				normal,
		weight-bold:		bold,
		kerning-alt:		0.25em
	);

// Palette.
	$palette: (
		bg:					#312450,
		bg-alt:				darken(#312450, 5),
		fg:					rgba(255, 255, 255, 0.9),
		fg-bold:			#ffffff,
		fg-light:			rgba(255,255,255,0.35),
		border:				rgba(255,255,255,0.15),
		border-bg:			rgba(255,255,255,0.05),
		accent1:			#d22424,
		accent1-alt:		darken(#d22424, 10),
		accent2:			#7385b3,
		accent2-alt:		darken(#7385b3, 10),
		accent3:			#a71f83,
		accent3-alt:		darken(#a71f83, 10),
		accent4:				#1ba77a,
		accent4-alt:		darken(#1ba77a, 10),
		accent5:				rgb(220, 64, 150),
		accent5-alt:		darken(rgb(220, 64, 150), 10),
	);