html,
body {
  background: #473968;
  min-height: 100%;
}

.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-table {
  display: table;
}

.vertical-center-row {
  display: table-cell;
  vertical-align: middle;
}

.main-content {
  margin-bottom: 10em;
  z-index: 1;
}

.container-canvas {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.name {
  text-align: center;
}

.loading::after {
  display: inline-block;
  animation: dotty steps(1,end) 4s infinite;
  content: '';
}

@keyframes dotty {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}